import { LazyLoad } from './modules/lazy-load.min.js';
import { Swipers } from './modules/swipers.min.js';

import { Header } from './modules/header.min.js';
import { BeeInfo } from './modules/bee-info.min.js';
import { Company } from './modules/company.min.js';
import { Products } from './modules/products.min.js';

let appLenis = {};
let swup = null;

const isPhone = window.innerWidth <= 768;

// Initialize page
function init() {
  // Initialize Gsap + ScrollTrigger
  gsap.registerPlugin(ScrollTrigger);

  // Initialize Swup
  swup = new Swup({
    containers: ['#swup', '#header', '#footer'],
    cache: false,
  });

  // Init scroll monitor
  transitionCallback();

  // Set transition callback
  swup.hooks.on('content:replace', transitionCallback);

  // Reload iubenda iframes
  swup.hooks.on('page:view', function () {
    if ($('.iubenda-embed').length > 0) {
      location.reload();
    }
  });
}

const initLenis = () => {
  appLenis = {
    initialized: false,
    _lenis: null,

    init() {
      const wrapper = document.getElementById('wrapper');

      this._lenis = new Lenis({
        wrapper: isPhone ? window : wrapper,
        eventsTarget: window,
      });

      const raf = (time) => {
        this._lenis.raf(time);
        requestAnimationFrame(raf);
      };

      requestAnimationFrame(raf);

      // Init scroll trigger
      this._lenis.on('scroll', ScrollTrigger.update);

      this.initialized = true;
    },

    scrollToTop() {
      // Scroll to top of the DOM to show all loaded items above subnav
      this._lenis.scrollTo(0, {
        immediate: true,
      });
    },

    destroy() {
      this._lenis.stop();
      this._lenis.destroy();
    },
  };

  appLenis.init();
};

function initComponents() {
  // Init Header to manage main menu openinig and lang selection
  new Header(appLenis);

  // Init BeeInfo class to set correct position ref to productHeader
  new BeeInfo(appLenis);

  // Init company page behavior
  new Company(appLenis);

  // Init product slider in product page
  new Products(appLenis);
}

const addPageClassToHTML = () => {
  const fromUrl = swup.visit.from.url;
  const toUrl = swup.visit.to.url;

  const getSplittedUrl = (url) => {
    // Get last splitted array item
    return url.split('?')[0].split('/').pop();
  };

  if (fromUrl && toUrl) {
    let fromClass = getSplittedUrl(fromUrl);
    if (fromClass === '') {
      fromClass = 'home';
    }
    document.body.parentNode.classList.remove(fromClass);

    let toClass = getSplittedUrl(toUrl);
    if (toClass === '') {
      toClass = 'home';
    }
    document.body.parentNode.classList.add(toClass);
  }
};

// Callback called on swup transition
function transitionCallback() {
  // Add page class to HTML element on page change
  addPageClassToHTML();

  // Close main menu if it is open
  document.body.parentNode.classList.remove('menu-open');

  // Stop lenis to prevent scrolling during transitions
  if (appLenis.initialized) {
    appLenis.destroy();
  }

  // Initialize Lenis
  if (!isPhone) {
    initLenis();

    // Scroll to top of the DOM
    appLenis.scrollToTop();
  } else {
    // Mobile Version: Scroll to top of the DOM on page transition
    setTimeout(() => {
      $('#wrapper').animate({ scrollTop: 0 }, 0);
    }, 10);
  }

  // Initialize swipers
  new Swipers().init();

  // Initialize lazy load
  new LazyLoad().init();

  // Initialize components
  initComponents();

  // Iubenda cleanup
  iubendaCheck();

  // Hide/Show reCaptcha badge based on page
  if ($('#contact-form').length > 0) {
    $('.grecaptcha-badge').addClass('visible');
  } else {
    $('.grecaptcha-badge').removeClass('visible');
  }
}

function iubendaCheck() {
  // Remove iubenda cookie message
  setTimeout(function () {
    if (_iub.cs.consent.purposes !== undefined) {
      let purposes_iub = Object.keys(_iub.cs.consent.purposes);

      for (let i = 0; i < purposes_iub.length; i++) {
        if (_iub.cs.consent.purposes[purposes_iub[i]] == true) {
          // Hide cookie message for enabled purposes
          $('.content-before-consent-' + purposes_iub[i]).each(function (_, el) {
            el.style.display = 'none';
          });

          // Send events to GTM
          switch (purposes_iub[i]) {
            case '2':
              // Attivatore Tag Manager categoria Interazioni Semplici
              dataLayer.push({
                event: 'iubenda-interazioni-semplici',
              });
              break;
            case '3':
              // Attivatore Tag Manager categoria Esperienza Migliorata
              dataLayer.push({
                event: 'iubenda-esperienza-migliorata',
              });
              break;
            case '4':
              // Attivatore Tag Manager categoria Misurazione
              dataLayer.push({ event: 'iubenda-misurazione' });
              break;
            case '5':
              // Attivatore Tag Manager categoria Pubblicità
              dataLayer.push({ event: 'iubenda-pubblicita' });
              break;
          }
        } else {
          // Show cookie message for disabled purposes
          $('.content-before-consent-' + purposes_iub[i]).each(function (_, el) {
            el.style.display = 'flex';
          });
        }
      }
    } else {
      // Show cookie message for disabled purposes
      $('.content-before-consent').each(function (_, el) {
        el.style.display = 'block';
      });
    }
    _iub.cs.api.activateSnippets();
  }, 500);
}

function removePreloader() {
  // Remove preloader
  $('.preloader').fadeOut(500);
}

// Initialize page
$(window).on('load', function () {
  init();
  removePreloader();
});
